// extracted by mini-css-extract-plugin
export var blockDescription = "payload-module--block-description--13d6d";
export var blocksContainer = "payload-module--blocks-container--41b47";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--4d4ed";
export var blocksInRowsWithOneActiveOuterWrapper = "payload-module--blocks-in-rows-with-one-active-outer-wrapper--00c9a";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--d65f4";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--dd944";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--7925a";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--0da9d";
export var coloredBlocksRightPart = "payload-module--colored-blocks-right-part--1bc28";
export var commonInnerWrapper = "payload-module--common-inner-wrapper--680f7";
export var commonTitlesContainer = "payload-module--common-titles-container--c486d";
export var contactButtonTextSection = "payload-module--contact-button-text-section--57b76";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--62d3a";
export var firstBlocksInRowsWithOneActiveSection = "payload-module--first-blocks-in-rows-with-one-active-section--1aa8f";
export var headerBorder = "payload-module--header-border--2a712";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--730c6";
export var headerTitle = "payload-module--header-title--112b5";
export var headerTitleBorderWidth = "payload-module--header-title-border-width--b9873";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--34160";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--27197";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--d4894";
export var opacitySliderVisibleText = "payload-module--opacity-slider-visible-text--45a82";
export var ourApproachInnerWrapper = "payload-module--our-approach-inner-wrapper--c3d8f";
export var secondBlocksInRowsWithOneActiveSection = "payload-module--second-blocks-in-rows-with-one-active-section--f1b94";
export var sectionUnderHeaderButton = "payload-module--section-under-header-button--17f17";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--5c814";
export var sectionUnderHeaderInnerWrapper = "payload-module--section-under-header-inner-wrapper--860c8";
export var sectionUnderHeaderSliderInnerWrapper = "payload-module--section-under-header-slider-inner-wrapper--f0e25";
export var sectionUnderHeaderText = "payload-module--section-under-header-text--d4979";
export var sectionUnderHeaderToggleVisibilityButton = "payload-module--section-under-header-toggle-visibility-button--50b02";
export var singleBlockContainer = "payload-module--single-block-container--4354c";
export var sliderWithClickableBlocksInnerWrapper = "payload-module--slider-with-clickable-blocks-inner-wrapper--5f126";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--254e8";
export var sliderWithClickableBlocksTitlesContainer = "payload-module--slider-with-clickable-blocks-titles-container--c23d3";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--e77ef";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--f0c0d";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--9bfc4";
export var techStackRectangle = "payload-module--tech-stack-rectangle--128d4";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--d9bfc";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--cf079";
export var techStackSubtitle = "payload-module--tech-stack-subtitle--e744c";
export var titlesContainer = "payload-module--titles-container--38b8d";